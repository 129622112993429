// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-course-js": () => import("/vercel/path1/src/templates/course.js" /* webpackChunkName: "component---src-templates-course-js" */),
  "component---src-templates-interested-js": () => import("/vercel/path1/src/templates/interested.js" /* webpackChunkName: "component---src-templates-interested-js" */),
  "component---src-templates-booking-js": () => import("/vercel/path1/src/templates/booking.js" /* webpackChunkName: "component---src-templates-booking-js" */),
  "component---src-templates-generic-pages-js": () => import("/vercel/path1/src/templates/generic-pages.js" /* webpackChunkName: "component---src-templates-generic-pages-js" */),
  "component---src-pages-404-js": () => import("/vercel/path1/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-for-foretag-js": () => import("/vercel/path1/src/pages/for-foretag.js" /* webpackChunkName: "component---src-pages-for-foretag-js" */),
  "component---src-pages-index-js": () => import("/vercel/path1/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("/vercel/path1/src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-utbildningar-js": () => import("/vercel/path1/src/pages/utbildningar.js" /* webpackChunkName: "component---src-pages-utbildningar-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/vercel/path1/.cache/data.json")

